import React, { useState, useEffect } from "react"
import type { ComponentType } from "react"

let resetFunctions = []
let formState = {}

export function withForm(Component): ComponentType {
    return (props) => {
        return <Component {...props} />
    }
}

export function withInputField(Component): ComponentType {
    return (props) => {
        const [value, setValue] = useState("")

        useEffect(() => {
            resetFunctions.push(() => setValue(""))
            return () => {
                resetFunctions = resetFunctions.filter(
                    (func) => func !== setValue
                )
            }
        }, [])

        const handleChange = (event) => {
            setValue(event.target.value)
            formState[props.name] = event.target.value
        }

        return (
            <input
                {...props}
                value={value}
                onChange={handleChange}
                style={{
                    width: "100%",
                    height: "100%",
                    backgroundColor: "transparent",
                    color: "black",
                    fontSize: "15px",
                    fontFamily: "Inter, sans-serif", // Set the font to Inter.
                    border: "none", // Remove the border.
                    padding: "15px",
                    borderRadius: "8px",
                    outline: "none",
                }}
                placeholder="e-mail"
            />
        )
    }
}

export function withTextAreaField(Component): ComponentType {
    return (props) => {
        const [value, setValue] = useState("")

        useEffect(() => {
            resetFunctions.push(() => setValue(""))
            return () => {
                resetFunctions = resetFunctions.filter(
                    (func) => func !== setValue
                )
            }
        }, [])

        const handleChange = (event) => {
            setValue(event.target.value)
            formState[props.name] = event.target.value
        }

        return (
            <textarea
                {...props}
                value={value}
                onChange={handleChange}
                style={{
                    width: "100%",
                    height: "100%",
                    backgroundColor: "transparent",
                    color: "black",
                    fontSize: "15px",
                    fontFamily: "Inter, sans-serif",
                    border: "none",
                    padding: "15px",
                    borderRadius: "8px",
                    resize: "none", // Usually we don't want textarea to be resizable
                    outline: "none",
                }}
                placeholder="Type..."
            />
        )
    }
}

export function withDropdownField(Component): ComponentType {
    return (props) => {
        const [value, setValue] = useState("")

        useEffect(() => {
            resetFunctions.push(() => setValue(""))
            return () => {
                resetFunctions = resetFunctions.filter(
                    (func) => func !== setValue
                )
            }
        }, [])

        const handleChange = (event) => {
            setValue(event.target.value)
            formState[props.name] = event.target.value
        }

        // Hardcoded options
        const options = [
            { value: "option1", label: "Option 1" },
            { value: "option2", label: "Option 2" },
            { value: "option3", label: "Option 3" },
            // more options...
        ]

        return (
            <select
                {...props}
                value={value}
                onChange={handleChange}
                style={{
                    width: "100%",
                    height: "100%",
                    backgroundColor: "transparent",
                    color: "black",
                    fontSize: "15px",
                    fontFamily: "Inter, sans-serif",
                    border: "none",
                    padding: "15px",
                    borderRadius: "8px",
                    outline: "none",
                }}
            >
                {options.map((option, index) => (
                    <option key={index} value={option.value}>
                        {option.label}
                    </option>
                ))}
            </select>
        )
    }
}

export function withCheckboxField(Component): ComponentType {
    return (props) => {
        const [checked, setChecked] = useState(false)

        useEffect(() => {
            resetFunctions.push(() => setChecked(false))
            return () => {
                resetFunctions = resetFunctions.filter(
                    (func) => func !== setChecked
                )
            }
        }, [])

        const handleChange = (event) => {
            setChecked(event.target.checked)
            formState[props.name] = event.target.checked
        }

        return (
            <label
                style={{
                    display: "block",
                    fontFamily: "Inter, sans-serif",
                    fontSize: "12px",
                }}
            >
                <input
                    {...props}
                    type="checkbox"
                    checked={checked}
                    onChange={handleChange}
                    style={{
                        marginRight: "0px",
                    }}
                />
                {props.label}
            </label>
        )
    }
}

export function withSubmitButton(Component): ComponentType {
    const handleSubmit = async (event) => {
        event.preventDefault()

        try {
            const response = await fetch(
                "https://hooks.zapier.com/hooks/catch/1930288/3szsux4/",
                {
                    method: "POST",
                    body: JSON.stringify(formState),
                    headers: {
                        "Content-Type": "text/plain",
                    },
                }
            )

            if (response.ok) {
                console.log("Form successfully submitted")
                resetFunctions.forEach((reset) => reset())
            } else {
                console.log("Form submission failed")
            }
        } catch (error) {
            console.error("Error submitting form: ", error)
        }
    }

    return (props) => {
        return (
            <button
                {...props}
                onClick={handleSubmit}
                style={{
                    width: "100%",
                    height: "100%",
                    backgroundColor: "transparent", // Changed the background color to blue here.
                    border: "none",
                }}
            ></button>
        )
    }
}
